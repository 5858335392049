import React, {useRef} from 'react';
import Site from "../../../../../data/site/type";
import SiteRow from "./SiteRow";
import {Button} from "react-bootstrap";
import {useFetch} from "../../../../../hooks/hooks";
import {Link} from "react-router-dom";
import SiteProductListButton from "../../elements/SiteProductListButton";
import SiteExternalProductUpdateButton from "../../elements/SiteExternalProductUpdateButton";

interface Props {
    site: Site,
    disabled: boolean
}

function SiteRowInList({site, disabled}: Props) {
    const reset = useRef<() => void>(() => {
    });

    const [callback, loading] = useFetch();

    const block = disabled || loading;

    return (
        <SiteRow
            site={site}
            reset={reset}
            buttonArea={(
                <div className={"d-flex flex-column justify-content-between h-100"}>
                    <Link to={`/sites/${site.id}`}><Button className={"w-100"}>Посмотреть</Button></Link>
                    <SiteProductListButton site={site}/>
                    <SiteExternalProductUpdateButton disabled={block} executor={callback} site={site}/>
                </div>
            )}
        />
    );
}

export default SiteRowInList