import memoize from "lodash.memoize";
import {deleteRequest, getRequest, postRequest} from "../../utils/queryUtils";
import SearchFilters from "../commons/request/SearchFilters";
import {SearchResult} from "../commons/response/SearchResult";
import ProductWithExternal from "../report/type";
import {ExternalProduct, ExternalProductSearchFilters, ExtProductSiteStats} from "./type";

export function findExternalProducts(
    request?: ExternalProductSearchFilters
): Promise<SearchResult<ExternalProduct>> {
    return getRequest<SearchResult<ExternalProduct>>(
        "/external-products",
        request
    );
}

export function findExternalProductsByProductId(
    productId: String
): Promise<ExternalProduct[]> {
    return getRequest<ExternalProduct[]>(`/external-products/by-product/${productId}`);
}

export function findProductWithExternals(
    request?: SearchFilters
): Promise<SearchResult<ProductWithExternal>> {
    return getRequest<SearchResult<ProductWithExternal>>(
        "/external-products/link/products",
        request
    );
}

export async function unlinkExternal(externalId: string) {
    await postRequest("/external-products/unlink", {
        externalProductId: externalId
    });
}

export async function linkExternalWithProduct(productId: string, externalId: string) {
    await postRequest("/external-products/link", {
        productId: productId,
        externalProductId: externalId
    });
}

export function findExternalProductById(externalProductId: string) {
    return getRequest<ExternalProduct>(`/external-products/${externalProductId}`)
}

export function extProductsSiteStats(siteId: string): Promise<ExtProductSiteStats> {
    return getRequest<ExtProductSiteStats>(`/external-products/count/site/${siteId}`)
}


export const findExternalProductsMemoize = memoize(findExternalProducts);
export const findProductWithExternalsMemoize = memoize(findProductWithExternals);