import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {ExternalProduct} from "../../../../data/externalProduct/type";
import {Button, Card, CardProps} from "react-bootstrap";
import Image from "../../../image/Image";
import cl from "../../products/list/row/ProductRow.module.scss";
import clo from "./ExternalProductRow.module.scss";
import cn from 'classnames'
import {findSiteByIdMemoize} from "../../../../data/site/data";
import {findPriceByExternalProductIdMemoize} from "../../../../data/price/data";
import {formatDate} from "../../../../utils/timeUtils";
import {Link} from "react-router-dom";
import Site from "../../../../data/site/type";

interface Props extends CardProps {
    externalProduct: ExternalProduct,
    buttons: ReactElement<typeof Button> | Array<ReactElement<typeof Button>>;
    additional?: React.ReactChild;
}

function ExternalProductRow({externalProduct, buttons, className,additional, ...other}: Props) {
    const {id, name, siteId, url, createAt, updateAt, lastSync} = externalProduct;
    const [price, setPrice] = useState<string>("Загружается");
    const [site, setSite] = useState<Site>();


    useEffect(function () {
        findSiteByIdMemoize(siteId)
            .then(s => setSite(s));
    }, []);

    useEffect(function () {
        findPriceByExternalProductIdMemoize(id)
            .then(s => setPrice(s.price.toString()));
    }, []);

    const siteText = useMemo(function () {
        if (site)
            return <Link to={`/sites/${site.id}`}>{site.name}</Link>;
        else
            return "Загружается";
    }, [site]);

    return (
        <Card className={cn([clo.block, "p-3", className])}  {...other}>
            <div className={cl.productLine}>
                <Image className={cl.img} src={externalProduct.imageUrl} fluid={false}/>
                <div className={cn([
                    cl.info,
                ])}>

                    <div className={"d-flex flex-column"}>
                        <p><a href={url}>{name}</a></p>
                        <span>Цена: {price}</span>
                        <span>Сайт: {siteText}</span>
                    </div>
                    <div className={"d-flex flex-column"}>
                        <span>Создан: {formatDate(createAt)}</span>
                        <span>Изменён: {formatDate(updateAt)}</span>
                        <span>Последний синхрон: {formatDate(lastSync)}</span>
                    </div>
                </div>
                <div className={cl.actionDiv}>
                    {buttons}
                </div>
            </div>
            {additional}
        </Card>
    );
}

export default ExternalProductRow;