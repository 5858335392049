import React, {useEffect, useRef} from 'react';
import {NavProps} from "./NavProps";

function InfiniteScrollNav<D>({
                                  provider,
                                  data,
                                  setData,
                                  pageSize,
                                  cleanRef,
                                  pagination: {page: {setPage, page}, count: {count, setCount}, loading}
                              }: NavProps<D>) {

    const lastElement = useRef<HTMLDivElement>(null);
    const observer = useRef<IntersectionObserver | null>(null);

    const updateList = function (_page: number) {
        if (loading)
            return;

        provider(_page)
            .then(result => {
                if (_page > 0)
                    setData([...data, ...result.list]);
                else
                    setData(result.list);

                setPage(_page);
                setCount(result.count)
            })
    }

    cleanRef.current = () => updateList(0);

    useEffect(function () {
        const callback = function (entries: IntersectionObserverEntry[]) {
            const currentItemCount = pageSize * page;
            if (entries[0].isIntersecting && data.length && !loading && currentItemCount < count) {
                updateList(page + 1);
            }
        }
        if (lastElement.current) {
            observer.current?.disconnect();
            observer.current = new IntersectionObserver(callback);
            observer.current.observe(lastElement.current);
        }
    }, [data, page, count, loading]);

    return (
        <div ref={lastElement}/>
    );
}

export default InfiniteScrollNav;