import React, {useState} from 'react';
import cl from "./AuthPage.module.scss"
import {Button, Form} from "react-bootstrap";
import {Auth} from "../../../data/auth/type";
import {authRequest} from "../../../data/auth/data";
import {useFetch} from "../../../hooks/hooks";
import ParentLoader from "../../loader/ParentLoader";
import {useNavigate} from "react-router-dom";


function AuthPage() {

    const [auth, setAuth] = useState<Auth>({
        login: "",
        password: ""
    });

    const [callback, loading] = useFetch();
    const nav = useNavigate();

    function login(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        callback(() => authRequest(auth))
            .then(t => {
                if (t) {
                    localStorage.setItem("token", t);
                    nav("/");
                }
            });
    }

    return (
        <div className={cl.root}>
            <Form className={"w-50 card p-3"} style={{position: "relative"}} onSubmit={login}>
                <Form.Group className="mb-3">
                    <Form.Label>Логин</Form.Label>
                    <Form.Control
                        value={auth.login}
                        onChange={e => setAuth({...auth, login: e.target.value})}
                        type="text"
                        placeholder="Введите логин"
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control
                        value={auth.password}
                        onChange={e => setAuth({...auth, password: e.target.value})}
                        type="password"
                        placeholder="Введите пароль"
                    />
                </Form.Group>
                <Button type={"submit"}>Вход</Button>

                {loading && <ParentLoader/>}
            </Form>
        </div>
    );
}

export default AuthPage;