import axios, {AxiosRequestConfig} from "axios";

const host = process.env.REACT_APP_HOST_URL as string;
const api = "/api";

function toString(obj: any) {
    if (obj instanceof Date)
        return obj.toISOString();
    else if (obj === undefined)
        return "";
    else if (typeof obj === "object")
        return obj.toString();
    else
        return obj;
}

function needGoDeeper(obj: any) {
    // noinspection RedundantIfStatementJS
    if (obj instanceof Date)
        return false;
    else if (typeof obj !== "object")
        return false;

    return true;
}

function fixedEncodeURIComponent(str: string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}

function serialize(obj: any, prefix?: string): string {
    const str = [];
    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            const k = prefix ? prefix + "." + p : p,
                v = obj[p];
            if (v === undefined)
                continue;
            else if (v !== null && needGoDeeper(v))
                str.push(serialize(v, k));
            else
                str.push(fixedEncodeURIComponent(k) + "=" + fixedEncodeURIComponent(toString(v)));
        }
    }
    return str.join("&");
}

function getToken() {
    return localStorage.getItem("token");
}


const client = axios.create();


client.interceptors.request.use(
    function (config: AxiosRequestConfig) {
        if (config.headers === undefined)
            config.headers = {};

        const token = getToken();
        if (token)
            config.headers["x-auth-token"] = token;

        return config;
    },
    error => Promise.reject(error)
)

client.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error && error.response) {
            if (error.response.status === 401) {
                window.location.href = "/auth";
            }
        }
        return Promise.reject(error);
    });


export function getRequest<T>(path: string, params?: any): Promise<T> {
    return client
        .get<T>(host + api + path + "?" + serialize(params))
        .then((r) => {
            return r.data
        });
}

export function deleteRequest<T>(path: string, params?: any): Promise<T> {
    return client
        .delete<T>(host + api + path + "?" + serialize(params))
        .then((r) => r.data)
}

export function postRequest<T>(
    path: string,
    body?: any,
    params?: any
): Promise<T> {
    return client
        .post<T>(host + api + path + "?" + serialize(params), body)
        .then((r) => r.data)
}

export function putRequest<T>(
    path: string,
    body?: any,
    params?: any
): Promise<T> {
    return client
        .put<T>(host + api + path + "?" + serialize(params), body)
        .then((r) => r.data)
}
