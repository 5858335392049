import React, {HTMLAttributes} from 'react';
import {Form} from "react-bootstrap";
import {DateRange} from "../../data/commons/request/SearchFilters";

interface Props extends HTMLAttributes<HTMLDivElement> {
    range?: DateRange;

    setRange(r: DateRange | undefined): void;
}

function toHtml(d?: Date): string | undefined {
    if (d) {
        const isoStr = d.toISOString();
        return isoStr.substring(0, isoStr.length - 1);
    }
}

function fromHtml(html?: string): Date | undefined {
    if (html)
        return new Date(html);
}

function DateRangeSelector({range, setRange, ...other}: Props) {
    return (
        <div {...other}>
            <label>От</label>
            <Form.Control
                type="datetime-local"
                value={toHtml(range?.startAt)}
                onChange={(e) => setRange({...range, startAt: fromHtml(e.target.value)})}
            />
            <label>До</label>
            <Form.Control
                type="datetime-local"
                value={toHtml(range?.endAt)}
                onChange={(e) => setRange({...range, endAt: fromHtml(e.target.value)})}
            />
        </div>
    );
}

export default DateRangeSelector;