import React from 'react';
import cl from "./Loader.module.scss";
import Loader from "./Loader";

function ParentLoader() {
    return (
        <div className={cl.parentLoaderRoot}>
            <Loader/>
        </div>
    );
}

export default ParentLoader;