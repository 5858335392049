import React from 'react';
import cl from "./Modal.module.scss";
import cn from "classnames";

interface Props {

    children: React.ReactChild | React.ReactChild[];
    open: boolean;

    setOpen(closed: boolean): any;
}

function Modal({children, open, setOpen}: Props) {
    if (!open)
        return null;
    else
        return (
            <div
                className={cn(
                    cl.modalRoot, cl.open
                )}
                onClick={() => setOpen(false)}
            >
                <div className={cl.modalWindow} onClick={(e) => e.stopPropagation()}>
                    {children}
                </div>
            </div>
        );
}

export default Modal;