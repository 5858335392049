import React, { HTMLAttributes } from "react";
import cl from "./block.module.css";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: any;
}

export default function Block(props: Props) {
  const { children, className, ...other } = props;

  return (
    <>
      <div className={cl.block + " p-3 " + className} {...other}>
        {children}
      </div>
    </>
  );
}
