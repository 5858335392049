import React from 'react';
import Loader from "./UI/loader/Loader";

function Home() {
    return (
        <>
            <h1>Добро пожаловать в админку</h1>
        </>
    );
}

export default Home;