import React, {MutableRefObject, useEffect, useState} from 'react';
import {useFetch} from "../../../../hooks/hooks";
import {extProductsSiteStats} from "../../../../data/externalProduct/data";

interface Props {
    /**
     * ID сайта
     */
    siteId: string;
    /**
     * Стоит ли загружать это сразу, или дать пользователю нажать
     */
    load?: boolean;

    reset?: MutableRefObject<() => void>;
}

function SiteProductCounter({siteId, load = false, reset}: Props) {
    const [loaded, setLoaded] = useState(false);

    const [count, setCount] = useState<number>(0);
    const [callback, loading] = useFetch();

    useEffect(function () {
        if (reset)
            reset.current = () => setLoaded(false);
    }, [setLoaded]);

    useEffect(function () {
        if (load)
            refreshCount();
    }, []);

    function refreshCount() {
        setLoaded(false);
        callback(() => extProductsSiteStats(siteId))
            .then(r => {
                    if (r) {
                        setCount(r.count)
                        setLoaded(true);
                    }
                }
            )
    }

    let countElem;

    if (loaded)
        countElem = <a href={"#"} onClick={refreshCount}>{count}</a>;
    else if (loading)
        countElem = <span>Загрузка</span>;
    else
        countElem = <a href="#" onClick={refreshCount}>посчитать</a>;
    return (
        <span>Кол-во продуктов: {countElem}</span>
    );
}

export default SiteProductCounter;