import React from 'react';
import EditPage from "../../../page/EditPage";
import {findExternalProductById} from "../../../../data/externalProduct/data";
import {ExternalProduct} from "../../../../data/externalProduct/type";
import ExternalProductPage from "./ExternalProductPage";

function ExternalProductRoute() {
    return <EditPage<ExternalProduct>
        loader={(id) => findExternalProductById(id)}
        component={(p, f) => <ExternalProductPage externalProduct={p} fetch={f}/>}
    />;
}

export default ExternalProductRoute;