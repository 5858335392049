import React from 'react';
import ListResult from "../../../listResult/ListResult";
import SearchFilters from "../../../../data/commons/request/SearchFilters";
import {SearchResult} from "../../../../data/commons/response/SearchResult";
import {findSites, syncAllSiteProducts} from "../../../../data/site/data";
import Site from "../../../../data/site/type";
import SiteRowInList from "./row/SiteRowInList";
import {useFetch} from "../../../../hooks/hooks";
import {Button} from "react-bootstrap";
import Line from "../../../line/Line";
import {Link} from "react-router-dom";

function dateProvider(f: SearchFilters): Promise<SearchResult<Site>> {
    return findSites(f);
}

function render(e: Site, loading: boolean) {
    return <SiteRowInList
        key={e.id}
        site={e}
        disabled={loading}
    />
}

function SiteList() {
    const [callback, loading] = useFetch();

    function syncAll() {
        // noinspection JSIgnoredPromiseFromCall
        callback(syncAllSiteProducts);
    }

    document.title = "Список сайтов";

    return (
        <>
            <Line orientation={"r"}>
                <Button disabled={loading} onClick={syncAll}>Синхронизовать все</Button>
                <Link to={"/sites/create"}><Button>Создать</Button></Link>
            </Line>
            <ListResult<Site, SearchFilters>
                provider={dateProvider}
                componentRender={(e) => render(e, loading)}
            />
        </>
    );
}

export default SiteList;