import React, {HTMLAttributes, useMemo} from "react";
import Product from "../../../../../data/product/type";
import Block from "../../../../block/Block";
import cl from "./ProductRow.module.scss";
import {Button} from "react-bootstrap";
import Image from "../../../../image/Image";
import cn from "classnames";
import {Link} from "react-router-dom";
import {formatDate} from "../../../../../utils/timeUtils";

export interface ProductRowProps extends HTMLAttributes<HTMLDivElement> {
    product: Product;
    needWatchButton?: boolean
    buttonsArea?: React.ReactChild
    children?: React.ReactNode
}

export default function ProductRow({
                                       product,
                                       className,
                                       buttonsArea,
                                       needWatchButton = true,
                                       children,
                                       ...other
                                   }: ProductRowProps) {


    const quantitySpan = useMemo(function () {
        if (product.quantity >= 10)
            return <span className={"text-white"}>
                <div
                    style={{display: "inline-block"}}
                    className={cn(cl.quantityDiv, "card", "bg-success")}
                >
                    {product.quantity}
            </div>
        </span>
        else if (product.quantity > 5)
            return<span className={"text-white"}>
                <div
                    style={{display: "inline-block"}}
                    className={cn(cl.quantityDiv, "card", "bg-warning")}
                >
                    {product.quantity}
            </div>
        </span>
        else if (product.quantity > 0)
            return <span className={"text-white"}>
                <div
                    style={{display: "inline-block"}}
                    className={cn(cl.quantityDiv, "card", "bg-danger")}
                >
                    {product.quantity}
            </div>
        </span>
        else
            return <span className={"text-white"}>
                <div
                    style={{display: "inline-block"}}
                    className={cn(cl.quantityDiv, "card", "bg-secondary")}
                >
                    {product.quantity}
            </div>
        </span>
    }, [product.quantity]);

    return (
        <Block className={cn(className, cl.row, {[cl.noAssociate]: product.noAssociate})} {...other}>
            <div className={cl.productLine}>
                <Image className={cl.img} fluid={false} src={product.imageUrl}/>
                <div className={cl.info}>
                    <div className={"d-flex flex-column"}>
                        <p><a href={product.url}>{product.name}</a></p>
                        <span>Цена: {product.price}</span>
                        <span>Количество: {quantitySpan}</span>
                    </div>
                    <div className={"d-flex flex-column"}>
                        <span>Создан: {formatDate(product.createAt)}</span>
                        <span>Изменён: {formatDate(product.updateAt)}</span>
                    </div>
                </div>
                <div className={cl.actionDiv}>
                    {needWatchButton &&
                        <Link key={"edit"} to={"/products/" + product.id}>
                            <Button className={"w-100"} variant={"primary"}>Посмотреть</Button>
                        </Link>
                    }
                    {buttonsArea}
                </div>
            </div>
            {children}
        </Block>
    );
}
