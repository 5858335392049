import React from 'react';
import {Form} from 'react-bootstrap';

interface Props {
    available?: boolean,

    setAvailable(s: boolean | undefined): void
}

function OnlyAvailableFilter({available, setAvailable}: Props) {
    function onChange(value: boolean) {
        if (value)
            setAvailable(true);
        else
            setAvailable(undefined);
    }

    return (
        <Form.Check
            type="switch"
            label="Только доступные продукты"
            onChange={e => onChange(e.target.checked)}
        />
    );
}

export default OnlyAvailableFilter;