import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {NavProps} from "./NavProps";
import {Pagination} from "react-bootstrap";

const PAGE_CHUNK = 3;

function PaginationNav<D>({
                              setData,
                              provider,
                              pageSize,
                              cleanRef,
                              pagination
                          }: NavProps<D>) {

    const [loading, setLoading] = useState(false);

    const {count, setCount} = pagination.count
    const {page, setPage} = pagination.page

    const pageCount = useMemo(function () {
        return Math.ceil(count / pageSize)
    }, [count]);

    const updateList = useCallback(function (page: number) {
        if (page < 0)
            return;

        if (loading)
            return;

        setLoading(true)
        provider(page)
            .then(result => {
                setData(result.list);

                setPage(page)
                setCount(result.count)
                setLoading(false)
            })
    }, [page, loading, setLoading, provider, setData, setPage, setCount, setLoading]);

    useEffect(function () {
        cleanRef.current = () => updateList(0);
    }, [updateList]);

    const pagesElements = useMemo(function () {
        if (pageCount == 0)
            return null;

        let startBound = (page + 1) - PAGE_CHUNK;
        if (startBound <= 0)
            startBound = 1;

        let endBound = (page + 1) + PAGE_CHUNK;
        if (endBound > pageCount)
            endBound = pageCount;
        let array = [];

        function onClick(clickedPage: number) {
            if (clickedPage == page)
                return;
            else
                updateList(clickedPage)
        }

        array.push(<Pagination.First disabled={loading} key={"first"} onClick={() => onClick(0)}/>)
        for (let i = startBound; i <= endBound; i++) {
            array.push(
                <Pagination.Item
                    key={i}
                    active={(page + 1) == i}
                    disabled={loading}
                    onClick={() => onClick(i - 1)}>{i}
                </Pagination.Item>);
        }

        array.push(<Pagination.Last disabled={loading} key={"last"} onClick={() => updateList(pageCount - 1)}/>);

        return array;
    }, [page, pageCount, updateList]);


    return (
        <Pagination>
            {pagesElements}
        </Pagination>
    );
}

export default PaginationNav;