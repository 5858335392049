import React from 'react';
import {Button} from "react-bootstrap";
import Site from "../../../../data/site/type";
import {syncSiteProducts} from "../../../../data/site/data";

interface Props {
    site: Site;
    disabled: boolean;

    executor(c: () => any): Promise<any>;

    after?(): void;
}

function SiteExternalProductUpdateButton({site, executor, disabled, after}: Props) {
    async function updateSiteProducts() {
        await executor(() => syncSiteProducts(site.id));

        if (after) {
            after();
        }
    }

    return (
        <Button variant={"outline-secondary"} disabled={disabled} onClick={updateSiteProducts}>
            Обновить продукты
        </Button>
    );
}

export default SiteExternalProductUpdateButton;