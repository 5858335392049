import React, {useCallback, useEffect, useState} from "react";
import cn from 'classnames'

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
    src?: string;
    fluid?: boolean;
}

export default function Image({src, fluid = true, className, ...another}: Props) {
    const [url, setUrl] = useState(src);

    useEffect(function () {
        if (!url)
            setUrl("/images/not_found.png");
    }, []);

    const onError = useCallback(function () {
        setUrl("/images/error.png")
    }, [setUrl]);

    return <img alt={""} onError={onError} src={url} className={cn([
        {"img-fluid": fluid}, className
    ])}/>;
}
