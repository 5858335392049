import React, {HTMLAttributes} from 'react';
import cl from "./Line.module.scss"
import cn from "classnames";

interface Props extends HTMLAttributes<HTMLDivElement> {
    orientation: "r" | "l";
    children: React.ReactChild | React.ReactChild[];
}

function Line({orientation, children, className, ...another}: Props) {
    const orientationClass = orientation == "l" ? cl.left : cl.right;

    return (
        <div className={cn(cl.line, orientationClass, className)} {...another}>
            {children}
        </div>
    );
}

export default Line;