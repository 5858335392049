import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {ExternalProduct} from "../../../../data/externalProduct/type";
import {Button, Card, CardProps} from "react-bootstrap";
import Image from "../../../image/Image";
import cl from "../../products/list/row/ProductRow.module.scss";
import clo from "./ExternalProductRow.module.scss";
import cn from 'classnames'
import {findSiteByIdMemoize} from "../../../../data/site/data";
import {findPriceByExternalProductIdMemoize} from "../../../../data/price/data";
import {formatDate} from "../../../../utils/timeUtils";
import {Link} from "react-router-dom";
import Site from "../../../../data/site/type";
import ExternalProductRow from "./ExternalProductRow";
import Modal from "../../../modal/Modal";
import ExternalAdd from "../../products/row/external/ExternalAdd";
import ProductAdd from "../add/ProductAdd";
import Product from "../../../../data/product/type";
import {linkExternalWithProduct, unlinkExternal} from "../../../../data/externalProduct/data";
import ProductRow from "../../products/list/row/ProductRow";
import {findProductById} from "../../../../data/product/data";
import {useFetch} from "../../../../hooks/hooks";
import ParentLoader from "../../../loader/ParentLoader";

interface Props {
    externalProduct: ExternalProduct,
}

function ListExternalProductRow({externalProduct, ...other}: Props) {
    const [addOpen, setAddOpen] = useState<boolean>(false);
    const [viewProduct, setViewProduct] = useState<Product>();
    const [executor, loading] = useFetch()
    // Загрузим продукт с сервера если он есть
    useEffect(function () {
        const productId = externalProduct.productId;
        if (!productId)
            return
        executor(() => findProductById(productId))
            .then((p) => {
                if (p) setViewProduct(p)
            })
    }, [])

    function add(product: Product) {
        console.log("Trying add " + externalProduct.name);

        executor(() => linkExternalWithProduct(product.id, externalProduct.id))
            .then(() => {
                setViewProduct(product);
                setAddOpen(false)
            });
        setAddOpen(false);
    }

    function remove() {
        executor(() => unlinkExternal(externalProduct.id))
            .then(() => {
                setViewProduct(undefined)
            })
    }

    return <>
        <ExternalProductRow
            key={externalProduct.id}
            className={"mb-3"}
            buttons={[
                <Link key={"open"} to={`/externalProducts/${externalProduct.id}`}>
                    <Button className={"w-100"}>Посмотреть</Button>
                </Link>
            ]}
            externalProduct={externalProduct}
            additional={
                <>
                    {loading && <ParentLoader/>}
                    <div className={cl.line}/>
                    <div className={"d-flex justify-content-between mb-3"}>
                        <span>Привязан к:</span>
                        <Button onClick={() => setAddOpen(true)} variant={"primary"}>Привязать</Button>
                    </div>
                    {viewProduct &&
                        <ProductRow
                            product={viewProduct}
                            buttonsArea={
                                <Button key={"remove"} onClick={remove}
                                        variant={"danger"}>Убрать</Button>
                            }
                        />}
                </>}
            {...other}
        />
        <Modal open={addOpen} setOpen={setAddOpen}>
            <p>Привязка продукта для {externalProduct.name}</p>
            <ProductAdd onSelect={add}/>
        </Modal>
    </>

}

export default ListExternalProductRow;