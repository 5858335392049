import React from 'react';
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import Product, {ProductFilters} from "../../../../data/product/type";
import ListResult from "../../../listResult/ListResult";
import {findProducts} from "../../../../data/product/data";
import ProductRow from "../../products/list/row/ProductRow";

interface Props {
    onSelect(ex: Product): void
}

function mapper(e: Product, onSelect: (e: Product) => void) {
    return (<ProductRow
            key={e.id}
            className={"mb-3"}
            product={e}
            buttonsArea={
                <Button
                    key={"select"}
                    variant={"primary"}
                    onClick={() => onSelect(e)}>
                    Выбрать
                </Button>
            }
        />
    );
}

function ProductAdd({onSelect}: Props) {
    return (<ListResult<Product, ProductFilters>
        provider={search => findProducts(search)}
        componentRender={e => mapper(e, onSelect)}
        initFilters={{query: ""}}
    />)
}

export default ProductAdd;