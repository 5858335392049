import {SearchResult} from "../commons/response/SearchResult";
import {getRequest, postRequest} from "../../utils/queryUtils";
import Product, {ProductFilters} from "./type";

export function findProducts(
    request: ProductFilters
): Promise<SearchResult<Product>> {
    return getRequest<SearchResult<Product>>("/products", request);
}

export async function setNoProductAssociate(productId: string, noAssociate: boolean) {
    await postRequest(`/products/${productId}/no-associate`, {
        noAssociate: noAssociate
    });
}

export function findProductById(productId: string): Promise<Product> {
    return getRequest(`/products/${productId}`);
}