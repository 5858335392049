import React, {MutableRefObject, ReactChild} from 'react';
import Site from "../../../../../data/site/type";
import Block from "../../../../block/Block";
import {formatDate} from "../../../../../utils/timeUtils";
import SiteProductCounter from "../../elements/SiteProductCounter";

interface Props {
    site: Site
    buttonArea?: ReactChild | ReactChild[]
    reset?: MutableRefObject<() => void>
}

function SiteRow({site, reset, buttonArea = []}: Props) {
    return (
        <Block className="mb-3 d-flex justify-content-between">
            <div className={"d-flex flex-column"}>
                <h3>{site.name}</h3>
                <p>Последнее обновление: {formatDate(site.lastUpdateAt)}</p>
                <p><SiteProductCounter reset={reset} siteId={site.id}/></p>
            </div>
            <div>{buttonArea}</div>
        </Block>
    );
}

export default SiteRow;