import React, {useCallback, useState} from "react";
import {ExternalProduct} from "../../../../../data/externalProduct/type";
import cl from "./ProductRow.module.scss";
import {Button} from "react-bootstrap";
import ExternalProductRow from "../../../externalProducts/row/ExternalProductRow";
import Modal from "../../../../modal/Modal";
import ExternalAdd from "../../row/external/ExternalAdd";
import {linkExternalWithProduct, unlinkExternal} from "../../../../../data/externalProduct/data";
import {setNoProductAssociate} from "../../../../../data/product/data";
import {Link} from "react-router-dom";
import ProductRow, {ProductRowProps} from "./ProductRow";

interface Props extends ProductRowProps {
    externals: ExternalProduct[];
}

export default function FullProductRow(props: Props) {
    const {product, externals, ...other} = props;

    const [stateExternal, setStateExternal] = useState<ExternalProduct[]>(externals);
    const [addOpen, setAddOpen] = useState<boolean>(false);
    const [noAssociate, setNoAssociate] = useState<boolean>(product.noAssociate);

    const remove = useCallback(function (externalProduct: ExternalProduct) {
            console.log("Product id " + product.id + ", ext: " + externalProduct)
            unlinkExternal(externalProduct.id).then(() => {
                const array = stateExternal.filter(p => p.id != externalProduct.id)
                setStateExternal(array);
            })
        }, [stateExternal, setStateExternal]
    );

    const changeAssociate = useCallback(function () {
        const newVal = !noAssociate;
        setNoProductAssociate(product.id, newVal).then(() => {
            product.noAssociate = newVal;
            setNoAssociate(newVal)
        });
    }, [noAssociate, setNoAssociate]);


    const add = useCallback(function (externalProduct: ExternalProduct) {
        console.log("Trying add " + externalProduct.name);

        linkExternalWithProduct(product.id, externalProduct.id)
            .then(() => {
                let array = stateExternal.filter(p => p.id != externalProduct.id);
                array.push(externalProduct);
                setStateExternal(array);
                setAddOpen(false)
            });
        setAddOpen(false);
    }, [stateExternal, setStateExternal]);

    return (
        <ProductRow
            product={product}
            buttonsArea={
                <Button key={"no-associate"} onClick={changeAssociate} variant={"primary"}>Нет соотношения</Button>
            }
            {...other}
        >

            <div className={cl.line}/>

            <div className={"d-flex justify-content-between mb-3"}>
                <p>Внешние продукты:</p>
                <Button onClick={() => setAddOpen(true)} variant={"primary"}>Добавить</Button>
            </div>

            <div className={cl.externalList}>
                {stateExternal.map(e => <ExternalProductRow
                    buttons={[
                        <Link key={"edit"} to={`/externalProducts/${e.id}`}>
                            <Button className={"w-100"} variant={"primary"}>Посмотреть</Button>
                        </Link>,
                        <Button key={"remove"} onClick={() => remove(e)} variant={"danger"}>Убрать</Button>
                    ]}
                    key={e.id}
                    externalProduct={e}/>)}


            </div>
            <Modal open={addOpen} setOpen={setAddOpen}>
                <p>Добавленеие внешнего продукта для {product.name}</p>
                <ExternalAdd onSelect={add}/>
            </Modal>
        </ProductRow>
    );
}
