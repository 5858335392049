import React from 'react';
import {ExternalProduct, ExternalProductSearchFilters} from "../../../../../data/externalProduct/type";
import {Button} from "react-bootstrap";
import ExternalProductRow from "../../../externalProducts/row/ExternalProductRow";
import ListResult from "../../../../listResult/ListResult";
import {findExternalProducts} from "../../../../../data/externalProduct/data";
import {Link} from "react-router-dom";

interface Props {
    onSelect(ex: ExternalProduct): void
}

function mapper(e: ExternalProduct, onSelect: (e: ExternalProduct) => void) {
    return (<ExternalProductRow
            key={e.id}
            className={"mb-3"}
            externalProduct={e}
            buttons={
                <>
                    <Button
                        disabled={Boolean(e.productId)}
                        key={"select"}
                        variant={"primary"}
                        onClick={() => onSelect(e)}>
                        Выбрать
                    </Button>
                    {e.productId && <span>Уже привязан к <Link
                        target={"_blank"}
                        to={"/products/" + e.productId}>
                            продукту
                        </Link>
                    </span>}
                </>
            }
        />
    );
}

function ExternalAdd({onSelect}: Props) {
    return (<ListResult<ExternalProduct, ExternalProductSearchFilters>
        provider={search => findExternalProducts(search)}
        componentRender={e => mapper(e, onSelect)}
        initFilters={{query: ""}}
    />)
}

export default ExternalAdd;