import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./UI/layout/Main";
import {Route, Routes} from "react-router-dom";
import ProductList from "./UI/content/products/list/ProductList";
import ProductPageRoute from "./UI/content/products/page/ProductPageRoute";
import Home from "./Home";
import NotFound from "./NotFound";
import ExternalProductRoute from "./UI/content/externalProducts/page/ExternalProductRoute";
import ExternalProductList from "./UI/content/externalProducts/list/ExternalProductList";
import UpdaterPage from "./UI/content/updater/UpdaterPage";
import SiteList from "./UI/content/site/list/SiteList";
import SitePageRouter from "./UI/content/site/page/SitePageRouter";
import SiteCreatePage from "./UI/content/site/page/SiteCreatePage";
import AuthPage from "./UI/content/auth/AuthPage";

function App() {
    return (
        <Routes>
            <Route path="/auth" element={<AuthPage/>}/>
            <Route path="/" element={<Main/>}>
                <Route index element={<Home/>}/>
                <Route path={"*"} element={<NotFound/>}/>

                <Route path={"products"} element={<ProductList/>}/>
                <Route path={"products/:id"} element={<ProductPageRoute/>}/>

                <Route path={"externalProducts"} element={<ExternalProductList/>}/>
                <Route path={"externalProducts/:id"} element={<ExternalProductRoute/>}/>

                <Route path={"sites"} element={<SiteList/>}/>
                <Route path={"sites/create"} element={<SiteCreatePage/>}/>
                <Route path={"sites/:id"} element={<SitePageRouter/>}/>

                <Route path={"updater"} element={<UpdaterPage/>}/>
            </Route>
        </Routes>
    );
}

export default App;
