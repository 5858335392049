import React from 'react';
import CodeMirror from "@uiw/react-codemirror";
import {javascript} from "@codemirror/lang-javascript";

interface Props {
    value: string;

    setValue(s: string): void;
}

function CodeEditor({value, setValue}: Props) {
    return (
        <CodeMirror
            onChange={(change, info) => {
                if ((info as any).flags == 4)
                    setValue(change);
            }}
            height={"400px"}
            extensions={[javascript({jsx: false})]}
            value={value}
        />
    );
}

export default CodeEditor;