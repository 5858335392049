import React, {HTMLAttributes} from 'react';
import cl from "./Loader.module.scss";
import cn from "classnames";

interface Props extends HTMLAttributes<HTMLDivElement> {

}

function Loader({className, ...other}: Props) {
    return (
        <div className={cn(cl.loader, className)} {...other}/>
    );
}

export default Loader;