import {useState} from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {AxiosError} from "axios";
import ErrorResponse from "../data/commons/response/ErrorResponse";

export type Fetch = [
    <R>(execute: () => Promise<R>) => Promise<R | null>,
    boolean
];

function isAxiosError(error: any): error is AxiosError {
    return (error as AxiosError).isAxiosError !== undefined;
}

export function useFetch(): Fetch {
    const [loading, setLoading] = useState(false);

    async function executor<R>(execute: () => Promise<R>): Promise<R | null> {
        try {
            setLoading(true);
            return await execute();
        } catch (e) {
            let message;
            if (typeof e === "string") {
                message = e.toUpperCase(); // works, `e` narrowed to string
            } else if (isAxiosError(e)) {
                const casted = e as AxiosError<ErrorResponse>;

                message = casted.response?.data.error;
            } else if (e instanceof Error) {
                message = e.message; // works, `e` narrowed to Error
            }
            const swal = withReactContent(Swal);

            message = message?.replace("\n", "<br>");

            console.log(e);

            await swal.fire({
                titleText: "Произошла ошибка",
                html: message,
                icon: "error"
            });

            return null;
        } finally {
            setLoading(false);
        }
    }

    return [executor, loading];
}
