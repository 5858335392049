import React from 'react';
import EditPage from "../../../page/EditPage";
import Site from "../../../../data/site/type";
import {findSiteById} from "../../../../data/site/data";
import SitePage from "./SitePage";

function SitePageRouter() {
    return <EditPage<Site>
        loader={(id) => findSiteById(id)}
        component={(s, f, r) => <SitePage site={s} fetch={f} getter={r}/>}
    />;
}

export default SitePageRouter;