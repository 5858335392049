import React from 'react';
import Site from "../../../../data/site/type";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {stringify} from "querystring";

interface Props {
    site: Site
}

function SiteProductListButton({site}: Props) {
    return (
        <Link to={"/externalProducts?" + stringify({"siteId": site.id})}>
            <Button
                className={"w-100"}
                variant={"secondary"}
            >
                Список продуктов
            </Button>
        </Link>
    );
}

export default SiteProductListButton;