import React, {useEffect, useState} from 'react';
import Product from "../../../../data/product/type";
import {Fetch} from "../../../../hooks/hooks";
import {ExternalProduct} from "../../../../data/externalProduct/type";
import {findExternalProductsByProductId} from "../../../../data/externalProduct/data";
import FullProductRow from "../list/row/FullProductRow";

interface Props {
    product: Product,
    fetch: Fetch
}

function ProductPage({product, fetch: [executor]}: Props) {
    const [externals, setExternals] = useState<ExternalProduct[] | null>(null);

    document.title = product.name;

    useEffect(function () {
        executor(() => findExternalProductsByProductId(product.id))
            .then(ext => {
                ext && setExternals(ext)
            });
    }, []);

    return (
        <>
            <h2 className={"mb-3"}>Редактирование продукта</h2>
            {externals && <FullProductRow needWatchButton={false} product={product} externals={externals}/>}
        </>
    );
}

export default ProductPage;