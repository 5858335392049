import React from 'react';
import {Form} from 'react-bootstrap';

interface Props {
    noAssociate?: boolean,

    setNoAssociate(s: boolean | undefined): void
}

function NoAssociateFilter({noAssociate, setNoAssociate}: Props) {
    function onChange(value: boolean) {
        if (value)
            setNoAssociate(true);
        else
            setNoAssociate(undefined);
    }

    return (
        <Form.Check
            type="switch"
            label="Только не ассоциированные продукты"
            onChange={e => onChange(e.target.checked)}
        />
    );
}

export default NoAssociateFilter;