import React, {useEffect, useState} from 'react';
import {Fetch, useFetch} from "../../hooks/hooks";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../loader/Loader";
import {Button} from "react-bootstrap";
import cl from "./EditPage.module.scss";
import ParentLoader from "../loader/ParentLoader";

interface Props<T> {
    loader: (id: string) => Promise<T>,
    component: (t: T, f: Fetch, getter: () => Promise<T>) => React.ReactElement
}

function EditPage<T>({loader, component}: Props<T>) {
    const {id} = useParams<{ id: string }>();
    const [item, setItem] = useState<T | null>(null);
    const f: Fetch = useFetch();
    const [executor, loading] = f;
    const navigate = useNavigate();

    async function load(): Promise<T> {
        if (id) {
            const result = await executor(() => loader(id));
            if (result)
                return result;
        }
        throw "Null";
    }

    useEffect(function () {
        load().then(r => setItem(r));
    }, []);

    return (<>
        <Button variant={"secondary"} className={"mb-3"} onClick={() => navigate(-1)}>Назад</Button>
        <div className={cl.root}>
            {item ? component(item, f, load) : <></>}
            {loading && (<ParentLoader />)}
        </div>

    </>);
}

export default EditPage;