import React, {FormEvent, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import Line from "../../../line/Line";
import {SiteInput} from "../../../../data/site/type";
import {useFetch} from "../../../../hooks/hooks";
import ParentLoader from "../../../loader/ParentLoader";
import {createSite} from "../../../../data/site/data";
import {useNavigate} from "react-router-dom";
import cl from "../../../main.module.scss";

function SiteCreatePage() {
    const [input, setInput] = useState<SiteInput>({
        name: "",
        script: ""
    });
    const [callback, loading] = useFetch();
    const navigate = useNavigate();

    async function create(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const result = await callback(() => createSite(input));

        if (result)
            navigate("/sites/" + result.id);

    }


    return (
        <Form onSubmit={create} className={"p-3 card"} style={{position: "relative"}}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Имя</Form.Label>
                <Form.Control
                    aria-required={true}
                    onChange={e => setInput({...input, name: e.target.value})}
                    value={input.name}
                    type="text"
                    placeholder="Введите имя сайта"
                />
            </Form.Group> <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Скрипт</Form.Label>
            <Form.Control
                className={cl.textarea}
                as={"textarea"}
                aria-required={true}
                onChange={e => setInput({...input, script: e.target.value})}
                value={input.script}
                type="text"
                placeholder="Введите скрипт сайта"
            />
            {loading && <ParentLoader/>}
        </Form.Group>
            <Line orientation={"r"}>
                <Button type={"submit"} disabled={input.name.length == 0 && input.script.length == 0}>Создать</Button>
            </Line>
        </Form>
    );
}

export default SiteCreatePage;