import {findProductWithExternals} from "../../../../data/externalProduct/data";
import ProductWithExternal from "../../../../data/report/type";
import ListResult from "../../../listResult/ListResult";
import SearchFilters from "../../../../data/commons/request/SearchFilters";
import NoAssociateFilter from "./filters/NoAssociateFilter";
import {ProductFilters} from "../../../../data/product/type";
import {Button} from "react-bootstrap";
import {useFetch} from "../../../../hooks/hooks";
import Line from "../../../line/Line";
import FullProductRow from "./row/FullProductRow";
import {syncProducts} from "../../../../data/updater/data";
import {useState} from "react";
import OnlyAvailableFilter from "./filters/OnlyAvailableFilter";

function filterRender(f: ProductFilters, set: (f: ProductFilters) => void) {
    return (
        <>
            <NoAssociateFilter
                noAssociate={f.noAssociate}
                setNoAssociate={(val) => {
                    set({...f, noAssociate: val})
                }}/>
            <OnlyAvailableFilter
                available={f.available}
                setAvailable={val => set({...f, available: val})}
            />
        </>);
}

export default function ProductList() {
    const [executor, loading] = useFetch();
    const [key, setKey] = useState(0);

    document.title = "Просмотр продуктов";

    function onSyncClick() {
        executor(() => syncProducts())
            .then(() => setKey(key + 1))
    }

    return (
        <>
            <Line orientation={"r"} className={"mb-3"}>
                <Button onClick={onSyncClick} disabled={loading}>Синхронизовать сейчас</Button>
            </Line>
            <ListResult<ProductWithExternal, SearchFilters>
                key={key}
                provider={search => findProductWithExternals(search)}
                type={"pagination"}
                filterRender={filterRender}
                componentRender={(p) => (
                    <FullProductRow
                        key={p.product.id}
                        className="mb-3"
                        product={p.product}
                        externals={p.externals}
                    />
                )}
            />
        </>
    );
}
