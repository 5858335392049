import {getRequest} from "../../utils/queryUtils";
import Price from "./type";
import memoize from "lodash.memoize";

export function findPriceByExternalProductId(
    id: string
): Promise<Price> {
    return getRequest<Price>("/prices/externalProduct/" + id + "/last");
}

export function findPricesByExternalProductId(
    id: string
): Promise<Price[]> {
    return getRequest<Price[]>("/prices/externalProduct/" + id);
}

export const findPriceByExternalProductIdMemoize = memoize(findPriceByExternalProductId);