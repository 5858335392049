import {getRequest, postRequest} from "../../utils/queryUtils";
import {Updater} from "./type";

export async function setUpdaterConfig(updater: Updater) {
    await postRequest("/updater", updater);
}

export function getUpdaterConfig(): Promise<Updater> {
    return getRequest<Updater>("/updater");
}

export async function syncProducts() {
    return await postRequest("/updater/run/product-sync");
}

export async function syncPrices() {
    return await postRequest("/updater/run/price-upload");
}