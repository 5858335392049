import React, {useEffect, useState} from 'react';
import {ExternalProduct} from "../../../../data/externalProduct/type";
import ExternalProductRow from "../row/ExternalProductRow";
import {Fetch} from "../../../../hooks/hooks";
import Price from "../../../../data/price/type";
import {findPricesByExternalProductId} from "../../../../data/price/data";
import {Table} from 'react-bootstrap';
import {formatDate} from "../../../../utils/timeUtils";
import {findProductById} from "../../../../data/product/data";
import Product from "../../../../data/product/type";
import ProductRow from "../../products/list/row/ProductRow";

interface Props {
    externalProduct: ExternalProduct,
    fetch: Fetch
}

function ExternalProductPage({externalProduct, fetch}: Props) {
    const [loader] = fetch;

    const [prices, setPrices] = useState<Price[]>([]);
    const [product, setProduct] = useState<Product>();

    document.title = externalProduct.name;

    useEffect(function () {
        async function loadInfo() {
            const extPrices = await loader(() => findPricesByExternalProductId(externalProduct.id));
            if (extPrices)
                setPrices(extPrices);
            const productId = externalProduct.productId;
            if (productId) {
                const product = await loader(() => findProductById(productId))
                if (product)
                    setProduct(product);
            }

        }

        loadInfo();
    }, []);

    return (
        <>
            <h3 className={"mb-3"}>Редактирование внешнего продукта</h3>
            <ExternalProductRow className={"mb-3"} externalProduct={externalProduct} buttons={[]}/>
            {product && (
                <>
                    <h3 className={"mb-3"}>Привязка к продукту</h3>
                    <ProductRow className={"mb-3"} product={product}/>
                </>
            )}


            <h3>Изменения цен</h3>
            <Table>
                <thead>
                <tr>
                    <th>Цена</th>
                    <th>Первый раз обнаружена</th>
                    <th>Держалась до</th>
                </tr>
                </thead>
                <tbody>
                {prices.map(p =>
                    <tr>
                        <td>{p.price}</td>
                        <td>{formatDate(p.createAt)}</td>
                        <td>{formatDate(p.updateAt)}</td>
                    </tr>
                )}
                </tbody>
            </Table>
        </>
    );
}

export default ExternalProductPage;