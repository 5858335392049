import React from 'react';
import EditPage from "../../../page/EditPage";
import {findProductById} from "../../../../data/product/data";
import ProductPage from "./ProductPage";
import Product from "../../../../data/product/type";


export default function ProductPageRoute() {
    return (<EditPage<Product>
        loader={(id) => findProductById(id)}
        component={(p, f) => (<ProductPage product={p} fetch={f}/>)
        }
    />);
}