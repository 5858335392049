import React, {useEffect, useMemo, useRef, useState} from 'react';
import Site from "../../../../data/site/type";
import {Fetch} from "../../../../hooks/hooks";
import {Button, Form} from "react-bootstrap";
import Line from "../../../line/Line";
import {isEqual} from 'lodash';
import {deleteSite, updateSite} from "../../../../data/site/data";
import SiteRow from "../list/row/SiteRow";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {useNavigate} from "react-router-dom";
import SiteExternalProductUpdateButton from "../elements/SiteExternalProductUpdateButton";
import SiteProductListButton from '../elements/SiteProductListButton';
import CodeMirror from "@uiw/react-codemirror";
import {javascript} from "@codemirror/lang-javascript";

interface Props {
    site: Site,
    fetch: Fetch,

    getter(): Promise<Site>;
}

function SitePage({site: siteInput, fetch, getter}: Props) {
    const nav = useNavigate();

    const [serverSite, setServerSite] = useState(siteInput);
    const [callback, loading] = fetch;
    const [site, setSite] = useState(siteInput);
    const reset = useRef(() => {
    });

    const blockSaveButton = useMemo(function () {
        return isEqual(site, serverSite);
    }, [site, serverSite]);

    useEffect(function () {
        reset.current();
    }, [serverSite]);

    async function updateSiteHandler() {
        const result = await callback(() => updateSite(site.id, site));

        if (result) {
            setSite(result);
            setServerSite(result);
        }
    }

    async function onDeleteClick() {
        const swal = withReactContent(Swal);

        const result = await swal.fire({
            titleText: "Подтвердите удаление",
            text: "Будет удалён сайт \"" + serverSite.name + "\", а вместе с ним и все продукты",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Удалить",
            cancelButtonText: "Оставить"
        });

        if (result.isConfirmed) {
            const r = await callback(() => deleteSite(site.id))

            if (r !== null) {
                nav("/sites");
            }
        }
    }

    document.title = serverSite.name;


    return (
        <>
            <SiteRow
                site={serverSite}
                reset={reset}
                buttonArea={(
                    <div className={"d-flex flex-column gap-2"}>
                        <SiteProductListButton site={serverSite}/>
                        <SiteExternalProductUpdateButton
                            site={serverSite}
                            disabled={loading}
                            executor={callback}
                            after={async () => {
                                const site = await getter();
                                setServerSite(site);
                                setSite(site);
                            }}/>
                    </div>
                )}
            />
            <h2>Редактирование</h2>
            <Form className={"card p-3"}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Имя</Form.Label>
                    <Form.Control
                        onChange={e => setSite({...site, name: e.target.value})}
                        value={site.name}
                        type="text"
                        placeholder="Введите имя сайта"
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Скрипт обновления</Form.Label>
                    <CodeMirror
                        onChange={(change,shit) => {
                            if (site.script != change)
                                setSite({...site, script: change})
                            else
                                console.log(shit);
                        }}
                        height={"400px"}
                        extensions={[javascript({jsx: false})]}
                        value={site.script}
                    />
                </Form.Group>
                <Line orientation={"r"}>
                    <Button onClick={onDeleteClick} variant={"danger"}>Удалить</Button>
                    <Button
                        onClick={() => setSite(serverSite)}
                        variant={"warning"}
                        disabled={blockSaveButton}>
                        Сброс изменений
                    </Button>
                    <Button onClick={updateSiteHandler} disabled={blockSaveButton}>Сохранить</Button>
                </Line>
            </Form>
        </>
    );
}

export default SitePage;