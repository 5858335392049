import {SearchResult} from "../commons/response/SearchResult";
import {deleteRequest, getRequest, postRequest, putRequest} from "../../utils/queryUtils";
import SearchFilters from "../commons/request/SearchFilters";
import Site, {SiteInput} from "./type";
import memoize from "lodash.memoize";

export function findSites(
    request: SearchFilters
): Promise<SearchResult<Site>> {
    return getRequest<SearchResult<Site>>("/sites", request);
}

export function findSiteById(
    id: string
): Promise<Site> {
    return getRequest<Site>("/sites/" + id);
}

export function updateSite(
    id: string,
    site: SiteInput
): Promise<Site> {
    return putRequest<Site>("/sites/" + id, site);
}

export async function deleteSite(id: string) {
    await deleteRequest("/sites/" + id);
}

export function createSite(site: SiteInput) {
    return postRequest<Site>("/sites", site)
}

export function findSitesByIds(
    id: string
): Promise<Site> {
    return getRequest<Site>("/sites/ids", {"ids": id});
}

export async function syncAllSiteProducts() {
    await postRequest("/sites/sync");
}

export async function syncSiteProducts(id: string) {
    await postRequest(`/sites/${id}/sync`);
}

export const findSiteByIdMemoize = memoize(findSiteById);