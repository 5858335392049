import React, {FormEvent, useEffect, useMemo, useState} from 'react'
import {useFetch} from "../../../hooks/hooks";
import Loader from "../../loader/Loader";
import {getUpdaterConfig, setUpdaterConfig, syncPrices, syncProducts} from "../../../data/updater/data";
import {Updater} from "../../../data/updater/type";
import {Button, Form} from "react-bootstrap";
import {isEqual} from 'lodash';
import Line from "../../line/Line";
import CodeEditor from "../../CodeEditor";

function UpdaterPage() {
    const [callback, loading] = useFetch();

    const [updaterDefault, setUpdaterDefault] = useState<Updater>({
        siteUpdateScript: "",
        priceUpdateScript: ""
    });

    const [updater, setUpdater] = useState<Updater>({
        siteUpdateScript: "",
        priceUpdateScript: ""
    });

    const changes = useMemo(function () {
        return !isEqual(updater, updaterDefault);
    }, [updater, updaterDefault]);

    useEffect(function () {
        callback(() => getUpdaterConfig())
            .then(function (u) {
                if (u) {
                    setUpdater(u);
                    setUpdaterDefault(u);
                }
            })
    }, []);

    async function onSubmit(e: FormEvent) {
        e.preventDefault();

        const result = await callback(() => setUpdaterConfig(updater));

        if (result !== null) {
            setUpdaterDefault(updater);
        }
    }

    function updatePrices() {
        callback(syncPrices);
    }

    function updateCatalog() {
        callback(syncProducts);
    }

    return (
        <div>
            <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3">
                    <div className={"d-flex justify-content-between mb-2 align-items-end"}>
                        <span>Скрипт обновления каталога</span>
                        <Button onClick={updateCatalog} disabled={loading} variant={"primary"}>Выполнить</Button>
                    </div>
                    <CodeEditor
                        value={updater.siteUpdateScript}
                        setValue={(s) => setUpdater({...updater, siteUpdateScript: s})}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <div className={"d-flex justify-content-between mb-2 align-items-end"}>
                        <span>Скрипт обновления цен на сайте</span>
                        <Button onClick={updatePrices} disabled={loading} variant={"primary"}>Выполнить</Button>
                    </div>
                    <CodeEditor
                        value={updater.priceUpdateScript}
                        setValue={(s) => setUpdater({...updater, priceUpdateScript: s})}
                    />
                </Form.Group>

                <Line orientation={"r"}>
                    <Button onClick={() => setUpdater(updaterDefault)} disabled={loading || !changes}
                            variant={"warning"}>Сбросить</Button>
                    <Button disabled={loading || !changes} type={"submit"} variant={"primary"}>Сохранить</Button>
                </Line>
            </Form>

            {loading && <Loader/>}
        </div>
    );
}

export default UpdaterPage;