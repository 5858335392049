import React from 'react';
import cl from "./Main.module.scss";
import {Link, NavLink, Outlet} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBoxArchive, faCode, faCube, faGlobe} from "@fortawesome/free-solid-svg-icons";
import cn from "classnames";

function a(b: boolean) {
    return {"active": b}
}

const list = [
    {
        name: "Продукты",
        icon: faCube,
        url: "/products"
    },
    {
        name: "Внешние продукты",
        icon: faBoxArchive,
        url: "/externalProducts"
    },
    {
        name: "Сайты",
        icon: faGlobe,
        url: "/sites"
    },
    {
        name: "Настройки скриптов",
        icon: faCode,
        url: "/updater"
    }
];

function Main() {
    return (
        <div className={cl.main}>
            <div className="d-flex flex-column flex-shrink-0 p-3 bg-light">
                <Link to="/"
                      className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                    <span className="fs-4">Instafermer</span>
                </Link>
                <hr/>
                <ul className="nav nav-pills flex-column mb-auto">
                    <li>
                        {list.map(e =>
                            <NavLink key={e.url} to={e.url}
                                     className={({isActive}) => cn("mb-2 nav-link link-dark", a(isActive))}>
                                <FontAwesomeIcon className={cl.marginRight} icon={e.icon}/>
                                <span>{e.name}</span>
                            </NavLink>
                        )}
                    </li>
                </ul>
                <hr/>
            </div>
            <div className={cl.content}>
                <div className={"container"}>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default Main;