import React, {ReactChild, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {SearchResult} from "../../data/commons/response/SearchResult";
import {Form} from "react-bootstrap";
import useDebounce from "../../utils/debounce";
import SearchFilters from "../../data/commons/request/SearchFilters";
import {Data, NavProps} from "./nav/NavProps";
import PaginationNav from "./nav/PaginationNav";
import {useFetch} from "../../hooks/hooks";
import Loader from "../loader/Loader";
import InfiniteScrollNav from "./nav/InfiniteScrollNav";
import cl from "./ListResult.module.scss";
import cn from "classnames";
import Block from "../block/Block";
import DateRangeSelector from "../dateRange/DateRangeSelector";

interface Props<T, F> {
    /**
     * Штука которая будет давать инфу
     */
    provider(search: F): Promise<SearchResult<T>>

    /**
     * Штука которая рендрит компонент
     */
    componentRender(data: T): React.ReactChild

    onFiltersChange?(f: F): void;

    initFilters?: F;
    type?: "infinite" | "pagination";
    topOfFilter?: React.ReactChild | ReactChild[];
    bottomOfFilter?: React.ReactChild | ReactChild[];

    /**
     * Дополнительные параметры для поиска
     */
    filterRender?(filter: F, setFilters: (f: F) => void): React.ReactChild
}

export const PAGE_SIZE = 25;
export default function ListResult<T, F extends SearchFilters>(
    {
        provider,
        componentRender,
        filterRender,
        type = "infinite",
        topOfFilter,
        bottomOfFilter,
        onFiltersChange,
        initFilters = {query: ""} as F
    }: Props<T, F>) {
    const [filters, setFilters] = useState<F>({...initFilters, limit: PAGE_SIZE});
    const [items, setItems] = useState<T[]>([]);
    const debounceFilters = useDebounce<F>(filters, 400);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [executor, loading] = useFetch();

    const pagination = useMemo(function () {
        return {
            count: {count, setCount},
            page: {page, setPage},
            loading
        };
    }, [page, count, loading, setPage, setCount]);

    const cleanRef = useRef(() => {
    });


    const providerMap = useCallback(async function (page: number): Promise<Data<T>> {
        const result = await executor(() => provider({...debounceFilters, offset: PAGE_SIZE * page}));
        if (result)
            return {
                list: result.list,
                count: result.size
            };
        else
            return {
                list: [],
                count: 0
            };
    }, [debounceFilters, executor, provider]);

    const typeProps: NavProps<T> = useMemo(function () {
        return {
            cleanRef,
            provider: providerMap,
            pageSize: PAGE_SIZE,
            pagination,
            data: items,
            setData: setItems
        };

    }, [provider, cleanRef, providerMap, pagination, items, setItems]);

    useEffect(function () {
        cleanRef.current();
        if (onFiltersChange)
            onFiltersChange(debounceFilters);
    }, [debounceFilters]);

    function body() {
        switch (type) {
            case "pagination":
                if (loading)
                    return <Loader/>
                else
                    return items.map(e => componentRender(e));
            case "infinite":
                const itemsList = items.map(e => componentRender(e));
                return (
                    <>
                        {itemsList}
                        {
                            loading && <Loader/>
                        }
                    </>
                )
        }
    }

    return (
        <>
            <div className={"d-flex justify-content-between"}>
                <p>Результатов: <b>{count}</b></p>
                {type == "pagination" && <PaginationNav {...typeProps} />}
            </div>
            <div className={cn(cl.list)}>
                <div className={cn(cl.filterArea)}>
                    {topOfFilter}
                    <Block className={cn(cl.filterRootBlock)}>
                        <Form.Control
                            className={"w-100"}
                            type="text"
                            placeholder="Поиск"
                            value={filters.query}
                            onChange={(e) => setFilters({...filters, query: e.target.value})}
                        />
                        <span>Создан</span>
                        <DateRangeSelector
                            range={filters.createAt}
                            setRange={r => setFilters({...filters, createAt: r})}
                            className={cn("card", cl.filterBlock)}
                        />
                        <span>Изменён</span>
                        <DateRangeSelector
                            range={filters.updateAt}
                            setRange={r => setFilters({...filters, updateAt: r})}
                            className={cn("card", cl.filterBlock)}
                        />
                        {filterRender && filterRender(filters, setFilters)}
                        <hr/>
                        <Form.Check
                            onChange={(e) => setFilters({...filters, invert: e.target.checked})}
                            type="switch"
                            label="Инвертировать"
                        />
                    </Block>
                    {bottomOfFilter}
                </div>
                <div className={cl.resultArea}>
                    {body()}
                    {type == "pagination" && !loading && <PaginationNav {...typeProps}/>}
                    {type == "infinite" && <InfiniteScrollNav {...typeProps}/>}
                </div>

            </div>
        </>)
}