import React, {useEffect} from 'react';
import ListResult from "../../../listResult/ListResult";
import {ExternalProduct, ExternalProductSearchFilters} from "../../../../data/externalProduct/type";
import ExternalProductRow from "../row/ExternalProductRow";
import {Link, useSearchParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import {SearchResult} from "../../../../data/commons/response/SearchResult";
import {findExternalProducts} from "../../../../data/externalProduct/data";
import {useFetch} from "../../../../hooks/hooks";
import SiteFilter from "../../site/ext/filters/SiteFilter";
import DateRangeSelector from "../../../dateRange/DateRangeSelector";
import cn from "classnames";
import cl from "../../../listResult/ListResult.module.scss";
import ListExternalProductRow from "../row/ListExternalProductRow";

function provider(f: ExternalProductSearchFilters): Promise<SearchResult<ExternalProduct>> {
    return findExternalProducts(f);
}

function filters(f: ExternalProductSearchFilters, setFilters: (f: ExternalProductSearchFilters) => void) {
    return <>
        <label>Последний синхрон</label>
        <DateRangeSelector
            className={cn("card", cl.filterBlock)}
            range={f.lastSync}
            setRange={r => setFilters({...f, lastSync: r})}
        />
        <SiteFilter
            site={f.siteId}
            setSite={(s) => {
                setFilters({...f, siteId: s})
                console.log(s)
            }}
        />
    </>
}

function ExternalProductList() {
    const [executor, loading] = useFetch();
    const [params, setParams] = useSearchParams();

    useEffect(function () {
        if (loading)
            return;

    }, [loading]);


    function onChange(f: ExternalProductSearchFilters) {
        const {siteId} = f;

        let params: Record<string, string> = {};

        if (siteId)
            params.siteId = siteId;

        setParams(params);
    }

    let initFilters: ExternalProductSearchFilters | undefined = undefined;

    if (params.has("siteId"))
        initFilters = {
            query: "",
            siteId: params.get("siteId")!
        };

    document.title = "Просмотр внешних продуктов";

    return (
        <ListResult<ExternalProduct, ExternalProductSearchFilters>
            provider={provider}
            type={"pagination"}
            filterRender={filters}
            initFilters={initFilters}
            onFiltersChange={onChange}
            componentRender={(p) =>
                <ListExternalProductRow
                    key={p.id}
                    externalProduct={p}/>
            }
        />
    );
}

export default ExternalProductList;