import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import Site from "../../../../../data/site/type";
import {findSites} from "../../../../../data/site/data";
import {useFetch} from "../../../../../hooks/hooks";

interface Props {
    site?: string

    setSite(s: string | undefined): void
}

function SiteFilter({site, setSite}: Props) {
    const [sites, setSites] = useState<Site[]>([]);

    const [executor, loading] = useFetch();

    useEffect(function () {
        executor(() => findSites({query: "", limit: 9999}))
            .then(s => s && setSites(s.list));
    }, []);

    return (
        <Form.Select value={site} disabled={loading} aria-label="Сайт" onChange={e => {
            const val = e.target.value
            if (val)
                setSite(e.target.value)
            else
                setSite(undefined);
        }}>
            <option key="main" value={""} onChange={() => setSite(undefined)}>Выберите сайт(пусто)</option>
            {sites.map(s => <option
                value={s.id}
                onChange={() => setSite(s.id)}
                key={s.id}>{s.name}
            </option>)}
        </Form.Select>
    );
}

export default SiteFilter;